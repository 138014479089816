import { useNavigate } from "react-router-dom";

const Navbar = () => {
  const navigate = useNavigate();

  const handleOpenProfile = () => {
    navigate('/profile');
  }

  return (
    <nav className="w-full px-6 py-3 flex items-center drop-shadow-md relative h-12 z-10 fixed top-0 left-0  bg-gradient-radial to-backgroundmint from-backgroundnavbar">
      <div className="flex-1 flex justify-center">
        <a href="/" className="flex items-center gap-2 p-0">
          <img src="/navlogo3.png" alt="BrainWaves Logo" className="h-12" /> {/* Replaced icon with image */}
        </a>
      </div>
      <button
        className="py-1 px-3 bg-transparent text-accent absolute right-2"
        onClick={handleOpenProfile}
      >
        <i className="fa-solid fa-gear text-xl"></i>
      </button>
    </nav>
  );
}

export default Navbar;