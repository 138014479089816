import { isCompositeComponent } from 'react-dom/test-utils';
import { GeneratedContent, Note } from '../types';

interface GenerateContentResponse {
  content: string;
}

export const generateContent = async (
  note_id: string,
  prompt_id: string
): Promise<string> => {
  try {
    const response = await fetch('/api/content/generate', {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify({ note_id: note_id, prompt_id: prompt_id }),
    });

    const isJsonResponse = response.headers.get('content-type')?.includes('application/json');
    if (!isJsonResponse) {
      throw new Error('Invalid JSON response');
    }

    const data: GenerateContentResponse = await response.json();

    if (response.ok) {
      return data.content;
    } else {
      throw new Error(data.content || 'Failed to generate content');
    }
  } catch (error) {
    console.error('Error generating content:', error);
    // Log silently and throw a generic error
    throw new Error('Content generation failed. Please try again later.');
  }
};

interface FetchGeneratedContentResponse {
  contents: GeneratedContent[];
}

export const fetchGeneratedContents = async (note_id: string): Promise<GeneratedContent[]> => {
  try {
    const response = await fetch(`/api/content/note/${note_id}`, {
      method: 'GET',
      credentials: 'include',
    });

    const isJsonResponse = response.headers.get('content-type')?.includes('application/json');
    if (!isJsonResponse) {
      throw new Error('Invalid JSON response');
    }

    const data: FetchGeneratedContentResponse = await response.json();

    if (response.ok) {
      return data.contents;
    } else {
      console.log(data.contents ? JSON.stringify(data.contents) : 'Failed to fetch generated content');
        return [];
    }
  } catch (error) {
    console.error('Error fetching generated content:', error);
    return [];
    
    }
  
};
