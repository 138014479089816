import React, { ReactElement } from "react";

const useModal = () => {
  let [modal, setModal] = React.useState<boolean>(false);
  let [modalContent, setModalContent] = React.useState<string | ReactElement>("I'm the Modal Content");
  let [modalButton, setModalButton] = React.useState<ReactElement | null>(null);

  let handleModal = (content: string | ReactElement = '', button?: ReactElement) => {
    if (content) {
      setModal(true); // Open modal
    } else {
      setModal(false); // Close modal
    }
    setModalContent(content);
    setModalButton(button || null);
  };

  return { modal, handleModal, modalContent, modalButton };
};

export default useModal;