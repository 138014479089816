import React, { useEffect } from 'react';
import useAuth from '../../utils/Auth';
import { useNavigate } from 'react-router-dom';

const ProfileScreen: React.FC = () => {
  const { user, signOut } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate('/', { replace: true });
    }
  }, [user]);

  const handleBack = () => {
    navigate(-1);
  }

  return (
    <div className="min-h-screen bg-text/10">
      <div className='pb-8 border-b border-text/10 bg-background'>
        <div className='w-full p-4 relative text-center mb-4'>
          <button onClick={handleBack} className='bg-transparent text-text px-2 py-1 absolute left-3 top-[10px] active:bg-text/50'>
            <i className="fa-solid fa-chevron-left text-xl"></i>
          </button>
          <span className='self-center text-xl font-semibold'>
            Profile
          </span>
        </div>
        <div className='h-28 w-28 rounded-full mx-auto bg-text border-2 border-accent' />
      </div>
      <div className='px-4 pb-4 pt-2 border-b border-text/10 bg-background '>
        <div className='text-sm text-primary tracking-widest'>
          username
        </div>
        <div>
          {user?.email}
        </div>
      </div>
      <div className='px-4 pb-4 pt-2 border-b border-text/10 bg-background drop-shadow-sm'>
        <div className='text-sm text-primary tracking-widest'>
          language
        </div>
        <select className='w-full bg-background text-text border-2 border-text/10 rounded-md p-1 mt-2'>
          <option value='en'>English</option>
          <option value='es'>Español</option>
          <option value='fr'>Français</option>
          <option value='de'>Deutsch</option>
        </select>
      </div>
      <div className='px-4 pb-4 pt-2 border-b text-center fixed bottom-0 left-0 right-0'>
        <button onClick={signOut} className='border border-text bg-transparent text-text text-center'>
          <i className="fa-solid fa-arrow-right-from-bracket mr-2"></i>
          <span>
            logout
          </span>
        </button>
      </div>
    </div>
  );
};

export default ProfileScreen;