import React, { useCallback, useEffect, useState } from 'react';
import NoteContent from './NoteContent';
import ActionGroup from './ActionGroup';
import GeneratedContentList from './GeneratedContentList';
import { Note, GeneratedContent } from '../../../types';
import { fetchGeneratedContents } from '../../../utils/ContentGeneration';
import toast from 'react-hot-toast';

interface NoteDetailsProps {
  note: Note;
  updateNote: (note: Note) => void;
  deleteNote: (id: string) => void;
  handleClose: () => void;
}

const NoteDetails: React.FC<NoteDetailsProps> = ({
  note,
  updateNote,
  deleteNote,
  handleClose,
}) => {
  const [similar, setSimilar] = useState<Note[]>([]);
  const [isEditing, setIsEditing] = useState(false);
  const [generatedContents, setGeneratedContents] = useState<GeneratedContent[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (note.is_fake != true) {
      fetchSimilarNotes();
      fetchAllGeneratedContents();
    }
  }, [note.id]);

  const fetchSimilarNotes = useCallback(async () => {
    console.log('Fetching similar notes...');
    try {
      const response = await fetch(`/api/notes/similar_notes/${note.id}`, {
        method: 'GET',
        credentials: 'include',
      });
      if (response.ok) {
        const data = await response.json();
        setSimilar(data.notes);
      } else {
        console.error('Failed to fetch similar notes');
      }
    } catch (error) {
      console.error('Error fetching similar notes:', error);
    }
  }, [note.id]);

  const fetchAllGeneratedContents = useCallback(async () => {
    try {
      const contents = await fetchGeneratedContents(note.id);
      setGeneratedContents(contents);
    } catch (err: any) {
      console.error('Failed to fetch generated contents:', err);
      toast.error(err.message || 'Failed to fetch generated contents');
    }
  }, [note.id]);

  const dateFormatter = (date: string) => {
    const d = new Date(date);
    const day = d.getDate();
    const month = d.toLocaleString('default', { month: 'short' });
    const hours = d.getHours();
    const minutes = d.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const ordinal =
      day % 10 === 1 && day !== 11
        ? 'st'
        : day % 10 === 2 && day !== 12
          ? 'nd'
          : day % 10 === 3 && day !== 13
            ? 'rd'
            : 'th';
    return `${formattedHours}:${formattedMinutes} ${ampm}, ${day}${ordinal} ${month}`;
  };

  const handleDelete = async () => {
    try {
      const response = await fetch(`/api/notes/delete/${note.id}`, {
        method: 'DELETE',
        credentials: 'include',
      });
      if (response.ok) {
        deleteNote(note.id);
        handleClose();
      } else {
        console.error('Failed to delete note');
      }
    } catch (error) {
      console.error('Error deleting note:', error);
    }
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
  };

  
  const addNewContent = (actionContent: GeneratedContent) => {
    setGeneratedContents((prevContents) => [actionContent, ...prevContents]);
  };

  return (
    <div className='relative p-4'>
      {/* Note Title */}
      <div className='font-bold text-md flex justify-between items-center'>
        <span>{note.title}</span>
        {note.is_fake != true &&
          <span
            onClick={handleDelete}
            className='font-bold text-[14px] bg-transparent text-secondary my-1 mx-2 focus:outline-none cursor-pointer'
            aria-label='Delete Note'
          >
            <i className='fa-solid fa-trash text-stop'></i>
          </span>
        }
      </div>

      {/* Divider */}
      <div className='bg-accent/50 w-full h-[1px] mb-1' />

      {/* Date and Edit Button */}
      <div className='flex justify-between items-center mb-4'>
        <div className='flex items-center gap-2 text-gray-400'>
          <i className='fa-regular fa-calendar text-[14px] -mt-[1px]'></i>
          <div className='text-xs'>{dateFormatter(note.created_at)}</div>
        </div>
        {note.is_fake != true && !isEditing && (
          <span
            onClick={handleEdit}
            className='bg-transparent text-accent shadow-sm ml-1 my-1 mx-2 flex items-center gap-1'
            aria-label='Edit Note'
          >
            <i className='fa-regular fa-pen-to-square fa-sm text-dark'></i>
          </span>
        )}
      </div>

      {/* Note Content */}
      <NoteContent
        note={note}
        updateNote={updateNote}
        isEditing={isEditing}
        handleCancelEdit={handleCancelEdit}
      />

      {note.is_fake != true &&
        <div>
          <div className='bg-accent/50 w-full h-[1px] my-4' />

          {/* Action Button Group */}
          <ActionGroup isLoading={isLoading} note_id={note.id}  addNewContent={addNewContent} /> </div>}

      {/* Divider */}
      <div className='bg-accent/50 w-full h-[1px] my-4' />

      {/* Generated Content List */}
      {
        generatedContents.length > 0 && (
          <GeneratedContentList generatedContents={generatedContents} />
        )
      }
    </div >
  );
};

export default NoteDetails;
