import React, { useEffect } from 'react';
import useAuth from '../../utils/Auth';
import { useNavigate } from 'react-router-dom';

const LoginScreen: React.FC = () => {
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      navigate('/notes', { replace: true });
    }
  }, [user]);

  return (
    <div className="h-screen bg-backgroundmint relative">
      {/* Full background image */}
      <img 
        src="/logo2.webp" 
        alt="BrainWaves" 
        className="absolute inset-0 w-full h-full object-cover z-0"
      />
      <div className='flex flex-col justify-between items-center relative z-10 h-full p-10'>        
        <h1 className="font-georgia text-5xl text-accent mb-20 mt-20">Brain Waves</h1> {/* Updated font family and increased size */}
        
        <div className="mt-auto mb-20"> {/* Added margin to position button */}
          <a
            href={`https://accounts.google.com/o/oauth2/v2/auth?client_id=${process.env.REACT_APP_GOOGLE_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}&response_type=code&scope=email`}
            className="button text-lg py-3 px-6 font-semibold rounded-full bg-gradient-to-r from-blue-400 to-pink-400 shadow-lg transition-transform transform hover:scale-105 hover:shadow-xl hover:from-blue-500 hover:to-pink-500" // Updated styles
          >
            <i className="fa-brands fa-google fa-lg text-white"></i> 
            <span className='px-2 text-white'> Sign in with Google</span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default LoginScreen;