import React, { ReactElement, useEffect } from "react";
import useModal from "./useModal";
import Modal from "./index";

let ModalContext:React.Context<any>;
const { Provider } = (ModalContext = React.createContext({
  modal: false,
  handleModal: () => {},
  modalContent: "" as string | ReactElement,
  modalButton: null as ReactElement | null,
}));

const ModalProvider = ({ children }: { children: ReactElement}) => {
  const { modal, handleModal, modalContent, modalButton } = useModal();

  return (
    <Provider value={{ modal, handleModal, modalContent, modalButton }}>
      <Modal />
      {children}
    </Provider>
  );
};

export { ModalContext, ModalProvider };