import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';

interface User {
  email: string;
  // Add other user properties as needed
  
}

const useAuth = () => {
  const getUserFromCookies = () => {
    const userCookie = Cookies.get('user');
    return userCookie ? JSON.parse(userCookie) : null;
  };

  const [user, setUser] = useState<User | null>(getUserFromCookies);

  useEffect(() => {
    // Always check auth status on initial load
    checkAuthStatus();
  }, []);

  const checkAuthStatus = () => {
    fetch('/api/auth/check-auth', {
      method: 'GET',
      credentials: 'include'
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      console.log('Check auth response:', data);
      if (data.authenticated) {
        // Store user info in a secure cookie
        Cookies.set('user', JSON.stringify(data.user), { secure: true, sameSite: 'Strict' });
        setUser(data.user);
      } else {
        Cookies.remove('user');
        setUser(null);
      }
    })
    .catch(error => {
      console.error('Error fetching auth status:', error);
      Cookies.remove('user');
      setUser(null);
    });
  };

  const signOut = () => {
    fetch('/api/auth/logout', {
      method: 'POST',
      credentials: 'include'
    })
    .then(response => response.json())
    .then(data => {
      console.log('Logout response:', data);
      Cookies.remove('user');
      setUser(null);
    })
    .catch(error => {
      console.error('Error during sign out:', error);
    });
  };

  return { user, signOut };
};

export default useAuth;
