import React, { useState, useEffect } from 'react';
import { Prompt } from '../../../types';
import toast from 'react-hot-toast';

interface ActionGroupProps {
  isLoading: boolean;
  note_id: string;
  addNewContent: Function;
}

const ActionGroup: React.FC<ActionGroupProps> = ({ isLoading, note_id, addNewContent  }) => {
  const [prompts, setPrompts] = useState<Prompt[]>([]);
  const [isFetching, setIsFetching] = useState(true);
  const [isSearchMode, setIsSearchMode] = useState(false); // State to toggle search mode
  const [searchTerm, setSearchTerm] = useState('');
  const [loadingPrompt, setLoadingPrompt] = useState<number | null>(null); // Track loading for individual buttons

  useEffect(() => {
    const fetchPrompts = async () => {
      try {
        const response = await fetch('/api/content/prompts', {
          method: 'GET',
          credentials: 'include',
        });
        if (response.ok) {
          const data: Prompt[] = await response.json();
          setPrompts(data);
        } else {
          console.error('Error fetching prompts:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching prompts:', error);
      } finally {
        setIsFetching(false);
      }
    };

    fetchPrompts();
  }, []);

  const handleGenerateContent = async (prompt_id: number) => {
    setLoadingPrompt(prompt_id); // Set loading for the specific button
    try {
      const response = await fetch(`/api/content/generate`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          prompt_id: prompt_id,
          note_id: note_id,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log('Generated content:', data);
        addNewContent(data);
      } else {
        console.error('Failed to generate content:', response.statusText);
      }
    } catch (error) {
      toast.error('Error generating content');
    } finally {
      setLoadingPrompt(null); // Reset loading
      if(isSearchMode) 
        handleSearchToggle();
    }
  };

  const handleSearchToggle = () => {
    setIsSearchMode(!isSearchMode);
  };

  const filteredPrompts = prompts.filter((prompt) =>
    prompt.display_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (isFetching || isLoading) {
    return <div className="mt-4">Loading...</div>;
  }

  return (
    <div className="mt-4">
      {isSearchMode ? (
        <div>
          <div className='flex items-center justify-between'>
          <input
            type="text"
            className="border p-2 rounded-full w-full text-sm"
            placeholder="Search actions..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <span  className='bg-stop text-white ml-2 py-2 px-3 rounded-full text-md flex items-center hover:bg-stop/80 transition-colors duration-200' onClick={handleSearchToggle}>
            <i className='fa fa-close' />
          </span>
          </div>
          <div className="mt-2">
            {filteredPrompts.map((prompt) => (
              <div
                key={prompt.id}
                onClick={() => handleGenerateContent(prompt.id)}
                className={`border-b py-2 cursor-pointer hover:bg-gray-100 rounded-md transition-colors duration-200 
                ${loadingPrompt === prompt.id ? 'opacity-50 cursor-wait' : ''}`}
              >
                <div className="bg-accent text-white py-1 px-3 rounded-full text-xs inline-block mb-1">
                  {prompt.display_name}
                </div>
                <div className="text-xs text-gray-500">{prompt.description}</div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="flex flex-wrap gap-2">
          {prompts.slice(0, 5).map((prompt) => (
            <button
              key={prompt.id}
              onClick={() => handleGenerateContent(prompt.id)}
              className={`bg-accent text-white py-1 px-3 rounded-full text-xs hover:bg-accent/80 transition-colors duration-200 
              ${loadingPrompt === prompt.id ? 'opacity-50 cursor-wait' : ''}`}
              disabled={isLoading || loadingPrompt === prompt.id}
            >
              {prompt.display_name}
            </button>
          ))}

          {/* Show search icon if there are more than 5 actions */}
          {prompts.length > 5 && (
            <button
              onClick={handleSearchToggle}
              className="bg-accent text-white py-1 px-3 rounded-full text-xs flex items-center hover:bg-accent/80 transition-colors duration-200"
            >
              <i className="fa-solid fa-magnifying-glass" />
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default ActionGroup;
