import React, { useState } from 'react';
import { GeneratedContent } from '../../../types';
import { copyToClipboard } from '../../../utils/helper';
import ReactMarkdown from 'react-markdown'; // Markdown rendering
import remarkGfm from 'remark-gfm'; // For GitHub-style markdown like tables and checklists

interface GeneratedContentListProps {
    generatedContents: GeneratedContent[];
}

const GeneratedContentList: React.FC<GeneratedContentListProps> = ({ generatedContents }) => {
    const [showAll, setShowAll] = useState(false);

    return (
        <div className='space-y-4'>
            <span className='text-secondary text-md font-semibold'>Generations</span>
            {generatedContents.slice(0, showAll ? generatedContents.length : 3).map((gc) => (
                <div key={gc.id} className='p-2 bg-accent/10 rounded-lg shadow-lg'>
                    <div className='flex justify-between items-start'>
                        <span className='text-accent text-xs'>{gc.prompt_display_name}</span>
                        <span
                            className="bg-secondary text-white py-0.5 px-2 rounded-full text-[10px] hover:bg-secondary/80 transition-colors duration-200"
                            onClick={() => copyToClipboard(gc.content)} // Call copyToClipboard on click
                            aria-label="Copy Generated Content"
                            role="button" // Optional: Makes it clear it's clickable
                            tabIndex={0} // Optional: Makes it focusable for accessibility
                        >
                            Copy
                        </span>
                    </div>

                    <div className='text-text text-sm markdown-content'>
                        <ReactMarkdown remarkPlugins={[remarkGfm]}>{gc.content}</ReactMarkdown>
                    </div>

                </div>
            ))}
            {generatedContents.length > 3 && (
                <div className='flex justify-center mt-4'>
                    {!showAll ? (
                        <button
                            className='text-accent text-sm py-2 px-4 rounded-lg border border-accent hover:bg-accent/20 transition duration-200'
                            onClick={() => setShowAll(true)}
                        >
                            Show More
                        </button>
                    ) : (
                        <button
                            className='text-accent text-sm py-2 px-4 rounded-lg border border-accent hover:bg-accent/20 transition duration-200'
                            onClick={() => setShowAll(false)}
                        >
                            Show Less
                        </button>
                    )}
                </div>
            )}
        </div>
    );
};

export default GeneratedContentList;
