import React, { useState } from 'react';
import { Note } from '../../../types';
import ReactMarkdown from 'react-markdown'; // Markdown rendering
import remarkGfm from 'remark-gfm'; // For GitHub-style markdown like tables and checklists

interface NoteContentProps {
  note: Note;
  updateNote: (note: Note) => void;
  isEditing: boolean;
  handleCancelEdit: () => void;
}

const NoteContent: React.FC<NoteContentProps> = ({
  note,
  updateNote,
  isEditing,
  handleCancelEdit,
}) => {
  const [content, setContent] = useState(note.content);
  const [error, setError] = useState<string | null>(null);

  const handleUpdate = async () => {
    setError(null);
    try {
      const response = await fetch(`/api/notes/update_content/${note.id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({ content }),
      });
      if (response.ok) {
        const updatedNote = await response.json();
        setContent(updatedNote.content);
        updateNote(updatedNote);
        handleCancelEdit();
      } else {
        const data = await response.json();
        throw new Error(data.error || 'Failed to update note');
      }
    } catch (error: any) {
      setError(error.message || 'Error updating note');
      console.error('Error updating note:', error);
    }
  };

  return (
    <div>
      {isEditing ? (
        <textarea
          className='mt-4 w-full h-40 focus:outline-accent p-2 border border-gray-300 rounded'
          value={content}
          onChange={(e) => setContent(e.target.value)}
          autoFocus
          aria-label='Edit Note Content'
        />
      ) : (
        <div className='mt-2 text-text text-sm p-1 markdown-content pre-wrap'>
              <ReactMarkdown remarkPlugins={[remarkGfm]}>{content}</ReactMarkdown>
              
        </div>
      )}

      {isEditing && (
        <div className='flex pt-2 gap-1'>
          <button
            onClick={handleCancelEdit}
            className='font-bold bg-white text-secondary py-1 px-3 flex items-center gap-1 rounded border border-gray-300'
            aria-label='Cancel Edit'
          >
            <i className='fa-solid fa-xmark'></i> Cancel
          </button>
          <button
            onClick={handleUpdate}
            className='bg-accent flex-1 font-bold text-sm flex items-center justify-center gap-2 py-1 px-3 rounded text-white'
            aria-label='Save Note'
          >
            <i className='fa-solid fa-check'></i> Save
          </button>
        </div>
      )}

      {/* Display Error Message */}
      {error && (
        <div className='mt-2 p-3 bg-red-100 text-red-700 rounded'>
          {error}
        </div>
      )}
    </div>
  );
};

export default NoteContent;
