import React, { useState } from 'react';
import { Note } from '../../../types';

interface NotesListProps {
  notes: Note[];
  onNoteClick: (note: Note) => void;
}

const NotesList: React.FC<NotesListProps> = ({ notes, onNoteClick }) => {
  const [uploadingNotes, setUploadingNotes] = useState<Note[]>([]);

  const formatTimestamp = (timestamp: string) => {
    const date = new Date(timestamp);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? ' pm' : ' am';
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

    return `${formattedHours}:${formattedMinutes}${ampm}`;
  };

  const formatForSeparator = (timestamp: string) => {
    const date = new Date(timestamp);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' });
    return `${month} ${day}`;
  };

  const groupNotesByDate = (notes: Note[]) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);

    return notes.reduce((groups: Record<string, Note[]>, note) => {
      const noteDate = new Date(note.created_at);
      noteDate.setHours(0, 0, 0, 0);

      let groupKey: string;

      if (noteDate.getTime() === today.getTime()) {
        groupKey = 'Today';
      } else if (noteDate.getTime() === yesterday.getTime()) {
        groupKey = 'Yesterday';
      } else {
        groupKey = formatForSeparator(note.created_at);
      }

      if (!groups[groupKey]) {
        groups[groupKey] = [];
      }
      groups[groupKey].push(note);

      return groups;
    }, {});
  };

  const groupedNotes = groupNotesByDate(notes);

  const renderNotes = (group: string, notes: Note[]) => {
    if (notes.length === 0) {
      return null;
    }

    return [
      <li key={`${group}-header`} className='text-secondary py-2 px-4 text-xl font-regular'>{group}</li>,
      <div key={`${group}-body`} className='drop-shadow-sm'>
        {notes.map((note, index) => {
          const formattedTimestamp = formatTimestamp(note.created_at);
  
          return (
            <li
              key={`${group}-${index}`}
              className={`overflow-hidden pt-2 pb-2 px-4 relative bg-background border-text/[0.05] border-b cursor-pointer hover:bg-text/[0.06] transition-all ${index === 0 && 'border-t'}`}
              onClick={() => onNoteClick(note)}
            >
              <div className=''>
                <div className='flex gap-2'>
                  <div className="truncate text-sm font-semibold text-text flex-1">
                    {note.title || "No title available"}
                  </div>
                  <div className='text-[10px] text-slate-500 text-right mb-[1px] flex gap-1 items-center font-thin'>
                    {/* <div className='mt-[1px]'>
                      <i className="fa-regular fa-clock"></i>
                    </div> */}
                    <div>{formattedTimestamp}</div>
                  </div>
                </div>
                <div className='text-[12px] text-text line-clamp-4'>
                  {note.content || "No transcript available"}
                </div>
              </div>
            </li>
          );
        })}
      </div>
    ];
  };

  return (
    <ul>
      {uploadingNotes.map((note, index) => {
        const formattedTimestamp = formatTimestamp(note.created_at);

        return (
          <li key={`uploading-${index}`} className='py-2 px-4 relative shadow-inner'>
            <div className='flex w-full'>
              <div className='flex-1 w-1/2'>
                <div className="truncate text-green-600 font-light">
                  Creating transcript...
                </div>
              </div>
              <div className='text-xs text-slate-500 text-right'>
                <div>{formattedTimestamp}</div>
              </div>
            </div>
          </li>
        );
      })}
      {Object.keys(groupedNotes)
        .sort((a, b) => {
          if (a === 'Today') return -1;
          if (b === 'Today') return 1;
          if (a === 'Yesterday') return -1;
          if (b === 'Yesterday') return 1;
          return new Date(b).getTime() - new Date(a).getTime();
        })
        .map((group) => renderNotes(group, groupedNotes[group]))}
    </ul>
  );
};

export default NotesList;
