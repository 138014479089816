import toast from "react-hot-toast";

export const generateUniqueId = () => `note-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;

export const copyToClipboard = async (text: string) => {
    try {
        await navigator.clipboard.writeText(text);
        toast.success('Copied to clipboard!'); // Display success toast
    } catch (error) {
        console.error('Failed to copy text:', error);
        toast.error('Failed to copy text'); // Display error toast
    }
};
