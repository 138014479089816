import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import './App.css';

import NotesScreen from './pages/notes';
import LoginScreen from './pages/login';
import ProfileScreen from './pages/profile';

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LoginScreen />} />
        <Route path="/notes" element={<NotesScreen />} />
        <Route path="/profile" element={<ProfileScreen />} />
        <Route path='*' element={<Navigate to='/' />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;