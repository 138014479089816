import React, { useState, useEffect, useContext } from 'react';
import NotesList from './NotesList';
import NoteDetails from './NoteDetails';
import { ModalContext } from '../../../utils/Modal/ModalProvider';
import { Note } from '../../../types';


interface NotesContainerProps {
  notesData: Note[];
}

const NotesContainer: React.FC<NotesContainerProps> = ({ notesData }) => {
  const [notes, setNotes] = useState<Note[]>([]);
  const { handleModal } = useContext(ModalContext);

  useEffect(() => {
    if (notesData.length > 0) {
      setNotes(notesData);
    }
  }, [notesData]);

  const openNote = async (note: Note) => {
    console.log('Opening note:', note);
    handleModal(
      <NoteDetails
        note={note}
        updateNote={updateNote}
        deleteNote={deleteNote}
        handleClose={() => handleModal(null)}
      />
    );
  }

  const updateNote = (note: Note) => {
    const updatedNotes = notes.map(n => {
      if (n.id === note.id) {
        return note;
      }
      return n;
    });
    setNotes(updatedNotes);
  }

  const deleteNote = (id: string) => {
    const updatedNotes = notes.filter(n => n.id !== id);
    setNotes(updatedNotes);
  }

  return (
    <div className="w-full pb-8">
      <NotesList notes={notes} onNoteClick={openNote} />
    </div>
  );
};

export default NotesContainer;