import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import { ModalContext } from "./ModalProvider";
import { BottomSheet, BottomSheetRef } from "react-spring-bottom-sheet";
import 'react-spring-bottom-sheet/dist/style.css';

const Modal = () => {
  let { modalContent, handleModal, modal, modalButton } = React.useContext(ModalContext);
  const sheetRef = useRef<BottomSheetRef>(null);

  const closeModal = () => {
    handleModal();
  };

  const handleInsideClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  if (modal) {
    const content = typeof modalContent === 'string' ? (
      <p>{modalContent}</p>
    ) : (
      modalContent
    );

    return ReactDOM.createPortal(
      <>
        {/* Modal for desktop */}
        <div
          className="fixed top-0 left-0 h-screen w-full md:flex items-center justify-center z-20 hidden"
          style={{ background: "rgba(0,0,0,0.8)" }}
          onClick={closeModal}
        >
          <div
            onClick={handleInsideClick}
            className="bg-white relative p-5 shadow-lg rounded flex flex-col items-start text-lg text-gray-800 overflow-y-auto"
            style={{ maxWidth: "600px", width: "90%", maxHeight: "75vh", margin: "0 auto" }}
          >
            {content}
          </div>
        </div>
        
        {/* BottomSheet for mobile */}
        <BottomSheet
          className="md:hidden"
          ref={sheetRef}
          open={modal}
          onDismiss={closeModal}
          snapPoints={({ maxHeight }) => [maxHeight * 0.5, maxHeight* 0.75]} // Snap points: 25%, 50%, and full height
          // style={{ maxHeight: "75vh" }} // Limit height to 75% of the viewport
        >
          <div className="pb-4 pt-1 px-4">
            {content}
          </div>
        </BottomSheet>
      </>,
      document.querySelector("#modal-root")!
    );
  } else return null;
};

export default Modal;
