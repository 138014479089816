import React from 'react';
import { createRoot } from 'react-dom/client';
import { Toaster } from 'react-hot-toast';

import App from './App';
import { ModalProvider } from './utils/Modal/ModalProvider';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(

  // <React.StrictMode>
    <ModalProvider>
      <Toaster />
      <App />
    </ModalProvider>
  // </React.StrictMode>
);

